.MuiContainer-root {
    max-width: 6000px;
}

.css-8oxboj-MuiInputBase-root-MuiTablePagination-select{
    margin-right: 0px;
    margin-left: 0px;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tss-1ork7hi-MUIDataTablePagination-tableCellContainer{
    padding: 0px 0px 0px 0px !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
    margin-left: 0px !important;
}